import { useState } from "react";
import { Link } from "react-router-dom";
import { products } from "../productsData";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="relative bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex-shrink-0">
          <div className="flex items-center">
            <img
              src="assets/Logo.png"
              alt="Logo"
              className="h-12 w-auto mr-2"
            />
            <div className="flex flex-col items-center space-y-[-6px]">
              <span className="text-white text-md font-bold">SSG GROUP</span>
              <div className="text-white text-md font-light uppercase">
                ALLUMINIUM
              </div>
            </div>
          </div>
        </Link>

        <div className="hidden md:block">
          <Link to="/" className="text-white mx-2">
            მთავარი
          </Link>

          <div
            onMouseEnter={() => setDropdownOpen(true)}
            onMouseLeave={() => setDropdownOpen(false)}
            className="relative inline-block text-white mx-2"
          >
            <Link to="/products" className="hover:underline">
              პროდუქცია
            </Link>
            {isDropdownOpen && (
              <div className="origin-top-right absolute right-0 mt-0 w-48 rounded-md shadow-lg bg-gray-700 text-white z-10">
                {products.map((product) => (
                  <Link
                    key={product.name}
                    to={`/products/${product.name}`}
                    className="block px-4 py-2 hover:bg-gray-800"
                  >
                    {product.name}
                  </Link>
                ))}
              </div>
            )}
          </div>

          <Link to="/contact" className="text-white mx-2">
            საკონტაქტო
          </Link>
        </div>

        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)} className="text-white">
            {isOpen ? "✖" : "☰"}
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="absolute left-0 w-full bg-gray-700 z-10">
          <Link
            to="/"
            onClick={() => setIsOpen(false)}
            className="block py-2 px-4 text-white"
          >
            მთავარი
          </Link>
          <Link
            to="/products"
            onClick={() => setIsOpen(false)}
            className="block py-2 px-4 text-white"
          >
            პროდუქცია
          </Link>
          {products.map((product) => (
            <Link
              key={product.name}
              to={`/products/${product.name}`}
              onClick={() => setIsOpen(false)}
              className="block py-2 px-4 text-white pl-8"
            >
              {product.name}
            </Link>
          ))}
          <Link
            to="/contact"
            onClick={() => setIsOpen(false)}
            className="block py-2 px-4 text-white"
          >
            საკონტაქტო
          </Link>
        </div>
      )}
    </div>
  );
}
