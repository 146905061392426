export default function Contact() {
  return (
    <div className="bg-gray-800 flex flex-col items-center justify-center h-full">
      <h2 className="text-3xl font-bold mb-8 text-white">საკონტაქტო</h2>
      <div className="mb-6 text-white text-left">
        <strong>Tel:</strong> (+995) 550-00-23-25
      </div>
      <div className="mb-6 text-white text-left">
        <strong>E-mail:</strong> ssgeoffice@gmail.com
      </div>
      <div className="mb-6 text-white text-left">
        <strong>Address:</strong> Nutsubidze St. 6
      </div>
    </div>
  );
}
