export const products = [
  {
    name: "ალუმინის კარ-ფანჯარა",
    image: "/assets/product1.jpg",
    description:
      "მაღალხარისხიანი, ეკოლოგიურად სუფთა პროფილებისგან დამზადებული პროდუქცია აბსოლუტურად იცავს შენობას წყლის შემოღწევისაგან, ქარისა და მტვრისაგან.",
  },
  {
    name: "ალუმინის ვიტრაჟული სისტემები",
    image: "/assets/product2.jpg",
    description: `ვიტრაჟული ტიხარი შესაძლებელია დამზადდეს:
  სტაციონარული შესრულებით;
  ორ ლიანდაგზე მოძრავი ორი, სამი, ოთხი ან ხუთ ფრთიანი გაგორების ფუნქციით.(მოძრაობს 8მმ-ის სიმაღლის ლიანდაგზე);
  ე.წ „დაკიდებული სისტემა“ ერთი ან ორი ფრთით;
  სურვილისამებრ შესაძლებელია როგორც სტანდარტული – გამჭვირვალე, ასევე დაბურული/ფერადი მინის გამოყენება.
  პროდუქტი პრაქტიკულია სტუდიო ტიპის სივრცისთვის, მისაღებისა და სამზარეულოს გამოსაყოფად. ამავდროულად  მინიმალისტურ, დეკორატიულ ფუნქციასაც ასრულებს ბინებში, აგარაკებსა და ოფისების ინტერიერში.`,
  },
  {
    name: "ალუმინის სლაიდური კარი",
    image: "/assets/product3.jpg",
    description: `ვიტრაჟული ტიხარი შესაძლებელია დამზადდეს:
  სტაციონარული შესრულებით;
  ორ ლიანდაგზე მოძრავი ორი, სამი, ოთხი ან ხუთ ფრთიანი გაგორების ფუნქციით.(მოძრაობს 8მმ-ის სიმაღლის ლიანდაგზე);
  ე.წ „დაკიდებული სისტემა“ ერთი ან ორი ფრთით;
  სურვილისამებრ შესაძლებელია როგორც სტანდარტული – გამჭვირვალე, ასევე დაბურული/ფერადი მინის გამოყენება.
  პროდუქტი პრაქტიკულია სტუდიო ტიპის სივრცისთვის, მისაღებისა და სამზარეულოს გამოსაყოფად. ამავდროულად მინიმალისტურ, დეკორატიულ ფუნქციასაც ასრულებს ბინებში, აგარაკებსა და ოფისების ინტერიერში.`,
  },
  {
    name: "ალუმინის საფასადე სისტემა",
    image: "/assets/product4.jpg",
    description: "ალუმინის საფასადე სისტემა",
  },
  {
    name: "მეტალოპლასმასის კარ-ფანჯარა",
    image: "/assets/product5.jpg",
    description: "მეტალოპლასმასის კარ-ფანჯარა",
  },
];
