import { products } from "../productsData";
import { Link } from "react-router-dom";

export default function Products() {
  return (
    <div
      className="py-10 container mx-auto text-white relative bg-center bg-cover min-h-screen px-4"
      style={{
        backgroundImage: "url(/assets/background-home.jpg)",
        backgroundColor: "#2D3748",
      }}
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black opacity-80"></div>

      {/* Content, positioned relatively on top of the overlay */}
      <div className="relative z-9">
        <h2 className="text-3xl font-bold mb-16 text-center">
          ჩვენი პროდუქცია
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map((product, idx) => (
            <div
              key={idx}
              className="flex items-center justify-center p-6 rounded shadow-lg bg-gradient-to-br from-gray-700 to-gray-900"
            >
              <Link
                to={`/products/${product.name}`}
                className="text-xl font-bold"
              >
                {product.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
