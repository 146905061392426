export default function Home() {
  return (
    <div
      className="lex flex-col justify-center items-center h-full bg-center bg-cover relative"
      style={{ backgroundImage: "url(/assets/background-home.jpg)" }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white">
        <p className="mt-4">
          კომპანია “SSG GROUP” გთავაზობთ უახლესი ევროპული დანადგარებითა და
          თანამედროვე ტექნოლოგიებით წარმოებულ ენერგოეფექტურ ალუმინის
          კარ-ფანჯრებსა და მინის საფასადე აქსესუარებს.
          ფურნიტურა:გერმანული,,ROTO,, ავსტრიული ,,MACO,, ხარისხი,რომელიც ზოგავს
          თქვენ ფინანსებს!
        </p>
      </div>
    </div>
  );
}
