import { useParams } from "react-router-dom";
import { products } from "../productsData";

export function ProductDetail() {
  const { productName } = useParams();
  const product = products.find((p) => p.name === productName);

  if (!product) return <div>Product not found</div>;

  return (
    <div
      className="py-10 container mx-auto text-white relative bg-center bg-cover min-h-screen px-4"
      style={{
        backgroundImage: "url(/assets/background-home.jpg)",
        backgroundColor: "#2D3748",
      }}
    >
      <div className="absolute inset-0 bg-black opacity-80"></div>
      <div className="relative z-9">
        <h2 className="text-3xl font-bold mb-8 text-center">{product.name}</h2>
        <div className="mx-auto w-full sm:w-4/5 md:w-2/3 lg:w-1/2">
          {" "}
          {/* Adjusted the margins and width here */}
          <div className="p-4 sm:p-6 rounded shadow-lg bg-gradient-to-br from-gray-700 to-gray-900">
            {" "}
            {/* Reduced padding for small screens */}
            <img
              src={product.image}
              alt={product.name}
              className="mb-4 rounded-md w-full"
            />
            <p className="text-white">{product.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
